import { Box } from '@material-ui/core'
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

// -------------------------------------------------------

const IMAGE_EXT = ['png', 'jpeg', 'jpg']

export function AttachmentImage(props) {
  const { attachment } = props

  const source = useMemo(() => {
    if (IMAGE_EXT.includes(attachment?.vca_extensao)) {
      return `data:image/${attachment.vca_extensao};base64,${attachment.attachment}`
    }

    return null
  }, [attachment])

  if (!source) return null

  return (
    <Box border="1px dashed" borderColor="divider">
      <img style={{ width: '100%' }} src={source} alt="" />
    </Box>
  )
}

AttachmentImage.propTypes = {
  attachment: PropTypes.object,
}
