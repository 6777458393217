import React, { useState, useCallback } from 'react'
import { FileArrowDown, FilePdf } from 'phosphor-react'
import { Box, CircularProgress, Collapse } from '@material-ui/core'
import formatter from '@/services/formatter'

import {
  TableCell,
  TableRow,
  Table,
  TableHead,
  TableBody,
} from '@/components/Table/styles'

import { DownloadButton } from './styles'
import { getInvoiceDanfe, getInvoiceXml } from '@/api/totvs'

// ---------------------------------------------------

export function InvoicesTableDetail(props) {
  const { invoices, show } = props

  return (
    <TableRow className="detail">
      <TableCell style={{ padding: 0 }} colSpan={4}>
        <Collapse in={show}>
          <Box padding="1rem" bgcolor="background.default">
            <Box bgcolor="background.paper">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>EMISSÃO</TableCell>
                    <TableCell>NOTA</TableCell>
                    <TableCell>CLIENTE</TableCell>
                    <TableCell>CIDADE</TableCell>
                    <TableCell>CHAVE</TableCell>
                    <TableCell>DOWNLOADS</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {invoices.map((invoice) => {
                    return (
                      <TableRow key={invoice.id}>
                        <TableCell>
                          {formatter(invoice.data_emissao).toSimpleDate()}
                        </TableCell>
                        <TableCell>{invoice.nota_fiscal}</TableCell>
                        <TableCell>{invoice.cliente_nome}</TableCell>
                        <TableCell>{invoice.cli_cidade}</TableCell>
                        <TableCell>{invoice.chave}</TableCell>
                        <TableCell>
                          <DownloadButtons invoice={invoice} />
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </Box>
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  )
}

// ---------------------------------------------------

function DownloadButtons({ invoice }) {
  const [loading, setLoading] = useState(false)

  const handleDownloadXml = useCallback(async () => {
    setLoading(true)

    await getInvoiceXml(invoice.chave)

    setLoading(false)
  }, [invoice])

  const handleDownloadPDF = useCallback(async () => {
    setLoading(true)

    await getInvoiceDanfe(invoice.chave)

    setLoading(false)
  }, [invoice])

  return (
    <Box display="flex" alignItems="center" gridGap="0.5rem">
      {loading ? (
        <CircularProgress size={16} />
      ) : (
        <Box width={16} height={16} />
      )}

      <DownloadButton
        size="small"
        color="inherit"
        variant="outlined"
        onClick={handleDownloadXml}
        disabled={loading}
        startIcon={<FileArrowDown />}
      >
        XML
      </DownloadButton>

      <DownloadButton
        size="small"
        color="inherit"
        variant="outlined"
        onClick={handleDownloadPDF}
        disabled={loading}
        startIcon={<FilePdf />}
      >
        PDF
      </DownloadButton>
    </Box>
  )
}
