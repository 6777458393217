import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Box, useMediaQuery } from '@material-ui/core'
import {
  WidgetsOutlined as BoxesIcon,
  GridOn as LoadedMetersComparativeIcon,
  AspectRatio as MetersIcon,
  WebAsset as PalletsIcon,
  Timer as PermanenceIcon,
  DonutLargeTwoTone as QuotaIcon,
  Schedule as ScheduleIcon,
  ClearAll as ShipmentComparativeIcon,
  LocalShippingOutlined as TrucksInAttendanceIcon,
  ViewListOutlined as WalletOrdersIcon,
  EventAvailable as CalendarIcon,
} from '@material-ui/icons'

import { useProfile } from '@/hooks'
import formatter, { parseDay } from '@/services/formatter'
import { getDashboardRequest } from '@/store/modules/users/dashboard/reducer'
import theme, { colors } from '@/theme'

import { Content } from '@/components'
import { Graph } from '@/components/Charts'
import { DailyQuotaProggressBar } from './components/daily-quota-proggress-bar'
import { Card, DashboardContainer, InfoText } from './styles'

// --------------- 𝕄𝕒𝕚𝕟 ---------------

export default function UsersHomePage() {
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const dispatch = useDispatch()

  const loading = useSelector((state) => state.users.dashboard.loading)

  const data = useSelector((state) => state.users.dashboard.data)

  const dashboardData = useMemo(
    () => ({
      summary: data.dashb_orders[0] || {},
      shipments: data.dashb_loadings[0] || {},
      monthlyShipments: data.dashb_shipments_count || [],
      monthlyShipmentsSquareMeters: data.dashb_shipments_sqmtr || [],
      quotaSummary: data.dashb_quota[0] || null,
      quota: data.dashb_quota.slice(1) || [],
    }),
    [data]
  )

  const {
    summary,
    shipments,
    monthlyShipments,
    monthlyShipmentsSquareMeters,
    quotaSummary,
    quota,
  } = dashboardData

  useEffect(() => {
    dispatch(getDashboardRequest())
  }, [dispatch])

  const profile = useProfile()

  const isAdmin = profile.isAdminCompany

  return (
    <Content
      loading={loading}
      title={
        profile.isAdminCompany
          ? 'Painel Adiministrativo'
          : 'Bem vindo, Transportador'
      }
    >
      <DashboardContainer>
        <Box
          maxWidth="min(1300px, 100%)"
          display="flex"
          flexDirection="column"
          margin="0 auto"
        >
          <Box
            display="flex"
            gridGap="1rem"
            justifyContent="space-between"
            flexDirection={isMobile ? 'column' : 'row'}
          >
            <Box
              display="grid"
              gridColumnGap="1rem"
              gridRowGap="1.5rem"
              gridAutoRows="max-content"
              gridTemplateColumns={
                isMobile ? 'repeat(1, 1fr)' : 'repeat(2, 1fr)'
              }
            >
              {isAdmin && (
                <Card
                  style={{ gridColumn: isMobile ? '1' : '1 / span 2' }}
                  title="Total Agendado - Metros Quadrados"
                  Icon={CalendarIcon}
                >
                  <Info value={summary.total_agendado} />
                </Card>
              )}

              <Card title="Disponível - Pallets" Icon={PalletsIcon}>
                <Info value={summary.total_pallets} />
              </Card>

              <Card title="Disponível - Caixas" Icon={BoxesIcon}>
                <Info value={summary.total_caixas} />
              </Card>

              <Card title="Pedidos em Carteira" Icon={WalletOrdersIcon}>
                <Info value={summary.total_pedidos_res} />
              </Card>

              <Card title="Disponível - Metros Quadrados" Icon={MetersIcon}>
                <Info value={summary.total_m2} />
              </Card>
            </Box>

            <Box
              display="grid"
              gridColumnGap="1rem"
              gridRowGap="1.5rem"
              gridTemplateColumns={
                isMobile || !isAdmin ? 'repeat(1, 1fr)' : 'repeat(2, 1fr)'
              }
            >
              {isAdmin && (
                <Card
                  style={{ gridRow: isMobile ? '1' : '1 / 4' }}
                  title={`Ating. cota fracionado ${
                    quotaSummary ? parseDay(quotaSummary.dia) : ''
                  }`}
                  Icon={QuotaIcon}
                >
                  <Info>
                    {`${formatNumber(quotaSummary?.quantidade)}/${formatNumber(
                      quotaSummary?.limite
                    )}`}
                  </Info>

                  <Box
                    display="flex"
                    flexDirection="column"
                    gridGap="0.5rem"
                    mt={1}
                  >
                    {quota.map((item) => {
                      return (
                        <DailyQuotaProggressBar
                          key={item.dia}
                          day={item.dia}
                          amount={item.quantidade}
                          percent={item.porcentagem}
                        />
                      )
                    })}
                  </Box>
                </Card>
              )}

              <Card
                variant="dark"
                title="Coletas confirmadas"
                Icon={ScheduleIcon}
              >
                <Info value={shipments.coletas_confirmadas} />
              </Card>

              <Card
                variant="dark"
                title="Coletas em atendimento"
                Icon={TrucksInAttendanceIcon}
              >
                <Info value={shipments.total_atual} />
              </Card>

              <Card
                variant="dark"
                title="Média de Permanência mês atual"
                Icon={PermanenceIcon}
              >
                <Info>
                  {shipments.med_horas}
                </Info>
              </Card>
            </Box>
          </Box>

          {/* === GRAPHS === */}
          <Box
            display="flex"
            flexDirection={isMobile ? 'column' : 'row'}
            gridGap="1rem"
            marginTop="1.5rem"
          >
            <Card
              title="Comparativo de Carregamentos - número de coletas atendidas"
              Icon={ShipmentComparativeIcon}
            >
              <Box marginTop={2}>
                <Graph
                  decimalCases={0}
                  color={colors.color300}
                  data={createGraphData(monthlyShipments, 'num_carreg')}
                />
              </Box>
            </Card>

            <Card
              title="Comparativo de Metros Quadrados Carregados"
              Icon={LoadedMetersComparativeIcon}
            >
              <Box marginTop={2}>
                <Graph
                  type="Line"
                  color={colors.color300}
                  data={createGraphData(monthlyShipmentsSquareMeters, 'num_m2')}
                />
              </Box>
            </Card>
          </Box>
        </Box>
      </DashboardContainer>
    </Content>
  )
}

// --------------- ℍ𝕖𝕝𝕡𝕖𝕣𝕤 ---------------

const createGraphData = (data, seriesAttr) => ({
  labels: data.map((item) => item.compet),
  series: [data.map((item) => item[seriesAttr]) || [0]],
})

const formatNumber = (value = 0) =>
  formatter(value).toDecimal({ decimalCases: 0 })

// --------------- ℂ𝕠𝕞𝕡𝕠𝕟𝕖𝕟𝕥𝕤 ---------------

function Info({ value, children }) {
  return <InfoText>{children || formatNumber(value)}</InfoText>
}
