import React from 'react'
import { Print as PrinCollect, Schedule } from '@material-ui/icons'
import { Button, Box, Divider, Typography } from '@material-ui/core'

import formatter from '@/services/formatter'

import { ChipStatus } from '@/components/ChipStatus'
import { TableHeaderContainer } from './styles'
import { CollectQuickFormEdit } from '../QuickEdit'

import environments from '@/consts/environments'

export function IssuedCollectsTableHeader({ data: collect, onPrint }) {
  const collectHeader = collect.header

  const enablePrint = collectHeader.vpt_data_cancelamento === null
  const enableEdit = !environments.PRODUCTION && collectHeader.editable

  return (
    <TableHeaderContainer>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h3">{collectHeader.id_coleta}</Typography>

        <Box gridGap="1rem" display="flex">
          <Typography component="span" variant="body2">
            <label>EMISSÂO:</label>
            {formatter(collectHeader.vpt_data_emissao).toSimpleDate()}
          </Typography>

          <Typography component="span" variant="body2">
            <label>COLETA: </label>
            {formatter(collectHeader.vpt_dcoleta).toSimpleDate()}
          </Typography>
        </Box>
      </Box>

      <Box display="flex" gridGap="1rem">
        <Typography component="span">
          {formatter(collectHeader.vpt_cod_motorista).toCPF()}
        </Typography>
        <Divider orientation="vertical" flexItem />
        <Typography component="span">{collectHeader.vpt_motorista}</Typography>
        <Divider orientation="vertical" flexItem />
        <Typography component="span">{collectHeader.vpt_placa}</Typography>
      </Box>

      <Box>
        <Typography component="p" variant="body2">
          {collectHeader.vpt_obs}
        </Typography>

        {collectHeader.vpt_data_cancelamento && (
          <Typography component="p" variant="body2" color="error">
            {formatter(collectHeader.vpt_data_cancelamento).toSimpleDate()}
            {'-'}
            {collectHeader.vpt_motivo_cancelamento}
          </Typography>
        )}
      </Box>

      <Box display="flex" alignItems="center" my="0.5rem">
        <Box display="flex" flex={1} gridGap="1rem">
          {enablePrint && (
            <Button
              size="small"
              variant="contained"
              color="primary"
              startIcon={<PrinCollect />}
              onClick={() => onPrint(collectHeader.id_coleta)}
            >
              Imprimir
            </Button>
          )}

          {enableEdit && (
            <CollectQuickFormEdit
              initialData={{
                id_coleta: collectHeader?.id_coleta,
                vpt_placa: collectHeader?.vpt_placa,
                vpt_motorista: collectHeader?.vpt_motorista,
                vpt_cod_motorista: collectHeader?.vpt_cod_motorista,
              }}
            />
          )}
        </Box>

        {collectHeader.status && (
          <ChipStatus
            icon={<Schedule color="inherit" fontSize="small" />}
            label={collectHeader.status.descr}
            background={collectHeader.status.color}
            className="large"
          />
        )}
      </Box>
    </TableHeaderContainer>
  )
}
