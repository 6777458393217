import { all, takeLatest, call, put } from 'redux-saga/effects'

import api from 'services/api'
import handleErrors from 'services/handleErrors'
import apiEndPoints from 'consts/apiEndPoints'

import {
  getBulletinsRequest,
  getBulletinsSuccess,
  getBulletinsFailure,
  readBulletinRequest,
  unreadedBulletinsRequest,
  unreadedBulletinsSuccess,
  createBulletinRequest,
  createBulletinSuccess,
  createBulletinFailure,
} from './reducer'
import { toast } from 'react-toastify'

function* getBulletins({ payload }) {
  try {
    const response = yield call(
      api.get,
      apiEndPoints.miscellaneous.bulletins(payload.status),
      {
        params: {
          limit: payload.limit,
          offset: payload.offset,
        },
      }
    )
    yield put(
      getBulletinsSuccess({
        bulletins: response.data,
        count: Number(response.headers.total),
      })
    )
  } catch (error) {
    yield put(getBulletinsFailure())
    handleErrors(error)
  }
}

function* readBulletin({ payload: bulletin }) {
  try {
    // read bulletin
    yield call(api.put, apiEndPoints.miscellaneous.readBulletin(bulletin.id))

    // fetch the new quantity of unread bulletins
    yield put(unreadedBulletinsRequest())
  } catch (error) {
    handleErrors(error, 'Não foi possível ler o aviso')
  }
}

function* unreadedBulletins() {
  try {
    const response = yield call(
      api.get,
      apiEndPoints.miscellaneous.unreadedBulletins
    )

    const unreadedBulletins = response.data

    yield put(unreadedBulletinsSuccess(unreadedBulletins.length))
  } catch (error) {
    handleErrors(error)
  }
}

function* createBulletin({ payload: { files, ...newBulletinRequestBody } }) {
  try {
    const response = yield call(
      api.post,
      apiEndPoints.miscellaneous.bulletins(),
      {
        bulletin: newBulletinRequestBody,
      }
    )

    const newBulletin = response.data
    const { id } = newBulletin
    yield put(createBulletinSuccess(newBulletin))

    if (files && files.length > 0) {
      yield call(uploadBulletinAttachments, id, files)
    }
    toast.success('Aviso criado com sucesso')
  } catch (error) {
    handleErrors(error, 'Não foi possível criar o aviso')
    yield put(createBulletinFailure())
  }
}

function* uploadBulletinAttachments(id, files) {
  try {
    for (const file of files) {
      const formData = new FormData()
      formData.append('file', file)
      yield call(
        api.post,
        apiEndPoints.miscellaneous.bulletinAttachments(id),
        formData
      )
    }
  } catch (error) {
    handleErrors(error, 'Não foi possível criar o anexo')
  }
}

export default all([
  takeLatest(getBulletinsRequest.type, getBulletins),
  takeLatest(readBulletinRequest.type, readBulletin),
  takeLatest(unreadedBulletinsRequest.type, unreadedBulletins),
  takeLatest(createBulletinRequest.type, createBulletin),
])
